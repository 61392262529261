<template>
    <v-container>
        <HeadbarPageComponent :title="'Emolumentos'"/>
        <v-card class="mt-2 pa-3">
            <v-row>
                <v-col md="2" lg="2">
                    <v-select
                        v-model="yearSelected"
                        :items="years"
                        label="Ano"
                        item-text="name"
                        item-value="value">
                    </v-select>
                </v-col>
                <v-col md="2" lg="2">
                    <v-select
                        solo
                        clearable
                        v-model="uf"
                        :items="listaUfs"
                        label="UF"
                        item-text="name"
                        item-value="value">
                    </v-select>
                </v-col>
                <v-col md="2" lg="2">
                    <v-text-field 
                        @input="vl_saldo_protesto = formatDinheiroString($event)"
                        v-model="vl_saldo_protesto"
                        prefix="R$"
                        clearable
                        label="">
                        
                    </v-text-field>
                </v-col>
                <v-col class="d-flex justify-end">
                    <v-btn
                        @click="projetarProxAno()"
                        color="primary"
                        dark>
                        Projetar para Prox Ano
                    </v-btn>
                </v-col>

            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        :disabled="emolumentos.length === 0"
                        class="primary white--text d-flex justify-center"
                        medium
                        v-model="botaoRelatorioXls"
                    >
                        <download-excel
                            :data="emolumentos"
                            :name="'Emolumentos-'+yearSelected+'.xls'"
                            worksheet="Emolumentos"
                            class="ma-auto"
                            type="xls"
                        >
                            <v-icon class="ma-3"
                                >mdi-microsoft-excel</v-icon
                            >Baixar XLS Resultados
                        </download-excel>
                    </v-btn>
                </v-col>
                <v-col class="d-flex justify-end">
                    <v-btn
                        @click="getEmolumentos()"
                        color="primary"
                        dark>
                        Pesquisar
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="mt-2 pa-3">
            <v-row>
                <v-col class="d-flex justify-end">
                    <v-btn
                        @click="dialogNewEmolumento = true"
                        color="primary"
                        dark>
                        <v-icon>mdi-plus</v-icon>
                        Adicionar
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="emolumentos"
                        :loading="loading"
                        :options="options"
                        :footer-props="{
                            itemsPerPageOptions: [100, 200, 300, -1],
                            itemsPerPageText: 'Itens por página:'
                        }"
                        class="elevation-1">
                            <template v-slot:item.vl_inicial="props">
                                <v-edit-dialog
                                    :return-value.sync="props.item.vl_inicial"
                                    @save="save(props.item)"
                                    
                                >
                                {{ mascaraMoeda(props.item.vl_inicial) }}
                                <template v-slot:input>
                                    <v-text-field
                                        @input="props.item.vl_inicial = formatDinheiroString($event)"
                                        v-model="props.item.vl_inicial"
                                        prefix="R$"
                                        label="Edit"
                                        single-line
                                        
                                    ></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </template>
                            <template v-slot:item.vl_custas="props">
                                <v-edit-dialog
                                :return-value.sync="props.item.vl_custas"
                                @save="save(props.item)"                                
                                >
                                {{mascaraMoeda(props.item.vl_custas) }}
                                <template v-slot:input>
                                    <v-text-field
                                    @input="props.item.vl_custas = formatDinheiroString($event)"
                                    v-model="props.item.vl_custas"
                                    prefix="R$"
                                    label="Edit"
                                    single-line
                                    ></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </template>
                            <template v-slot:item.vl_final="props">
                                <v-edit-dialog
                                :return-value.sync="props.item.vl_final"
                                @save="save(props.item)"
                                
                                >
                                {{mascaraMoeda(props.item.vl_final) }}
                                <template v-slot:input>
                                    <v-text-field
                                    @input="props.item.vl_final = formatDinheiroString($event)"
                                    v-model="props.item.vl_final"
                                    prefix="R$"
                                    label="Edit"
                                    single-line
                                    ></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </template>
                            <template v-slot:item.obs="props">
                                <v-edit-dialog
                                :return-value.sync="props.item.obs"
                                @save="save(props.item)"                                
                                >
                                {{ props.item.obs }}
                                <template v-slot:input>
                                    <v-text-field
                                    v-model="props.item.obs"
                                    label="Edit"
                                    single-line
                                    ></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </template>
                    </v-data-table>
                    <Snackbar
                        v-if="snack"
                        :mostrarSnackbar="snack"
                        :corSnackbar="snackColor"
                        :mensagemSnackbar="snackText"
                        @fecharSnackbar="snack = false"
                    />
                </v-col>
            </v-row>
            <v-dialog v-model="dialogNewEmolumento" max-width="600px">
                <v-card>
                    <v-card-title>Adicionar emolumento</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-select
                                    solo
                                    clearable
                                    v-model="newEmolumento.cd_uf"
                                    :items="listaUfs"
                                    label="UF"
                                    item-text="name"
                                    item-value="value">
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-select
                                    v-model="newEmolumento.ano_referencia"
                                    :items="years"
                                    label="Ano"
                                    item-text="name"
                                    item-value="value">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-text-field
                            @input="newEmolumento.vl_inicial = formatDinheiroString($event)"
                            prefix="R$"
                            v-model="newEmolumento.vl_inicial"
                            label="Valor Inicial"
                        ></v-text-field>
                        <v-text-field
                            @input="newEmolumento.vl_final = formatDinheiroString($event)"
                            prefix="R$"
                            v-model="newEmolumento.vl_final"
                            label="Valor Final"
                        ></v-text-field>
                        <v-text-field
                            @input="newEmolumento.vl_custas = formatDinheiroString($event)"
                            prefix="R$"
                            v-model="newEmolumento.vl_custas"
                            label="Valor Custas"
                        ></v-text-field>
                        <v-text-field
                            v-model="newEmolumento.obs"
                            label="Observação"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="createEmolumento()">Salvar</v-btn>
                        <v-btn @click="dialogNewEmolumento = false">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-container>
</template>
<script>
import emolumentosService from '@/services/EmolumentosService.js';
import HeadbarPageComponent from '@/components/atoms/HeadbarPage.vue';
import downloadExcel from 'vue-json-excel';
export default {
    name: "Emolumentos",
    components: {
        HeadbarPageComponent,
        downloadExcel
    },
    data() {
        return {
            dialogNewEmolumento: false,
            vl_saldo_protesto: '',
            botaoRelatorioXls: null,
            newEmolumento:{
                vl_inicial: '',
                vl_custas: '',
                vl_final: '',
                obs: '',
                cd_uf: '',
                ano_referencia: null
            },
            
            snack: false,
            snackColor: '',
            snackText: '',
            uf: '',
            ano_referencia:'',
            headers: [
                { text: 'Ano referência ', value: 'ano_referencia' },
                { text: 'UF', value: 'cd_uf' },
                { text: 'Valor Inicial', value: 'vl_inicial' },
                { text: 'Valor Final', value: 'vl_final' },
                { text: 'Valor Custas', value: 'vl_custas' },
                { text: 'Observação', value: 'obs' },
            ],
            options: {
                itemsPerPage: 100,
                page: 1
            },
            menu: false,
            emolumentosService: new emolumentosService(this.$http, this.$store),
            yearSelected: '',
            emolumentos: [],
            loading: false,
            years:[],
            listaUfs: [
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MT',
                'MS',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO'
            ],
        };
    },
    created() {
        this.years = this.getYears();
    },
    methods: {    
        projetarProxAno(){
            this.emolumentosService.projetarProxAno({projetar_proximo_ano: true, percentual_reajuste:5})
                .then(response => {
                    this.snack = true
                    this.snackColor = 'success'
                    this.snackText = response.mensagem
                })
                .catch(error => {
                    this.snack = true
                    this.snackColor = 'error'
                    this.snackText = error.mensagem
                    console.log(error);
                });
        },
        formatEmolumentoObj(emolumento){
            emolumento.vl_inicial = emolumento.vl_inicial.replace(',', '.');
            emolumento.vl_inicial = emolumento.vl_inicial.replace(/\.(?=[^.]*\.)/g, "");
            emolumento.vl_custas = emolumento.vl_custas.replace(',', '.');
            emolumento.vl_custas = emolumento.vl_custas.replace(/\.(?=[^.]*\.)/g, "");
            emolumento.vl_final = emolumento.vl_final.replace(',', '.');
            emolumento.vl_final = emolumento.vl_final.replace(/\.(?=[^.]*\.)/g, "");
            return emolumento;
        },
        createEmolumento(){
            let emolumento = this.formatEmolumentoObj(this.newEmolumento);
            this.emolumentosService.postEmolumento(emolumento).then(() => {
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Criado Emolumento com sucesso'
                this.dialogNewEmolumento = false;
                this.getEmolumentos();
            }).catch(error => {
                console.log(error);
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Erro ao criar Emolumento'
                this.dialogNewEmolumento = false;
            });

        },
        formatDinheiroString(valor) {
            valor = valor.replace(/\D/g, "");
            valor = valor.replace(/(\d)(\d{2})$/, "$1,$2");
            valor = valor.replace(/(?=(\d{3})+(\D))\B/g, ".");
            return valor ? valor : "";
        },
        mascaraMoeda(valor) {
            valor = Number(valor);
            if(!isNaN(valor) && valor !== 0){
                valor = valor.toFixed(2).split('.');
                valor[0] = valor[0].split(/(?=(?:...)*$)/).join('.');
                return "R$ " + valor.join(',');
            } else {
                return '';
            }
        },
        save (emolumento) {
            let emolumentoObj = this.formatEmolumentoObj(emolumento);
            this.emolumentosService.putEmolumento(emolumentoObj).then(() => {
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Atualizado emolumento com sucesso'
                this.getEmolumentos();
            }).catch(error => {
                console.log(error);
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Erro ao atualizar emolumento'
            });
            
        },
        
        getEmolumentos() {
            this.loading = true;
            let vl_saldo_protesto = this.vl_saldo_protesto.replace(/\D/g, '');
            this.emolumentosService.getEmolumentos({cd_uf:this.uf, ano_referencia: this.yearSelected, vl_saldo_protesto: vl_saldo_protesto})
                .then(response => {
                    this.emolumentos = response;
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                });
        },
        getYears() {
            let years = [];
            let currentYear = new Date().getFullYear();
            this.yearSelected = new Date().getFullYear();
            years.push({
                name: currentYear + 1,
                value: currentYear + 1
            });
            for (let i = 0; i < 10; i++) {
                years.push({
                    name: currentYear - i,
                    value: currentYear - i
                });
            }
            return years;
        }
    }
};
</script>